import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class ScheduleFoodTrailerPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container trailer-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={8}>
              <h5>
                **Please note that for all catering events, there will be a
                deposit of $150 Due at signing of agreement**
              </h5>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    displayLabel: "Name of Person Completing Form",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "date",
                    displayLabel: "Date",
                    placeholder: "Date",
                    type: "input",
                    inputType: "date",
                    required: false,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "eventName",
                    displayLabel: "What is your event name?",
                    placeholder: "What is your event name?",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "eventDateTime",
                    displayLabel: "Event Date & Time?",
                    placeholder: "Event Date & Time?",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "guest",
                    displayLabel: "How many people expected?",
                    placeholder: "How many people expected?",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "productToBeServed",
                    displayLabel:
                      "Are we catering this event? If so what product on the menu are you looking to be served?",
                    placeholder: "",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "vendor",
                    displayLabel:
                      "If not a catering event are you inviting us to be a food vendor?",
                    placeholder: "",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "associatedFees",
                    displayLabel: "What are the associated fees?",
                    placeholder: "",
                    type: "input",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
              <p>
                After completing these questions, someone from our team will
                reach out to you.
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Schedule Our Food Trailer" }) {
      title
    }
  }
`;
